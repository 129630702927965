import {
  booleanAttribute,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { debounceTime, throttleTime } from "rxjs/operators";
import { AutoUnsubscribe } from "../../decorators/auto-unsubscribe";
import { Tender } from '../../../tenders/models/tender';

@AutoUnsubscribe
@Component({
  selector: 'ex-note-editor',
  templateUrl: './ex-note-editor.component.html',
  styleUrls: ['./ex-note-editor.component.scss']
})
export class ExNoteEditorComponent implements OnChanges {

  @Input({required: true}) service!: NoteService<any>;
  @Input({required: true}) tender!: Tender;
  @Input() dbNote: string = '';
  @Input({transform: booleanAttribute}) displayText = false;
  @Input() context: 'in-list-view' | 'in-detail-view' = 'in-list-view';
  @ViewChild('wideNoteTextArea') wideNoteTextArea: ElementRef | undefined;
  @ViewChild('narrowNoteTextArea') narrowNoteTextArea: ElementRef | undefined;

  note: string = '';
  note$ = new BehaviorSubject<string>('');
  @Input() annotationId?: number;
  isPanelOpen = false;
  mode: 'edition' | 'view' = 'view';
  forceToggle$ = new EventEmitter<boolean>();
  headerHover = false;
  currentRequest?: Promise<void>;

  @Output() noteChange = new EventEmitter<string>()
  @Output() isEditorOpen = new EventEmitter<{id: string, isOpen: boolean}>()

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dbNote']) {
      this.note = this.dbNote;
    }
    if (changes['context'] && this.context === 'in-detail-view') {
      this.note$
        .pipe(
          debounceTime(750),
          throttleTime(1500, undefined, {leading: true, trailing: true})
        )
        .subscribe((_) => {
            const request = this.updateNote()
            this.currentRequest = request;
            request.then(() => {
              if (this.currentRequest === request)  this.currentRequest = undefined;
            })
          }
        )
    }
  }

  async getNote() {
    if (this.currentRequest) return;
    const currentNote = this.note;
    const {note, annotationId} = await this.service.getNote(this.tender.id);
    if (currentNote != note && !this.currentRequest) this.note = note;
    this.annotationId = annotationId;
  }

  async updateNote() {
    if (this.dbNote !== this.note) {
      if (this.annotationId) await this.service.updateNote(this.annotationId, this.note);
      if (!this.annotationId) {
        const res = await this.service.createNote(this.tender.id, this.note);
        this.tender.tenderAnnotationsId = res.tender_annotation_id;
      }
      this.noteChange.emit(this.note);
      this.dbNote = this.note;
    }
  }
  close() {
    this.mode = 'view';
    this.forceToggle$.emit(false);
  }

  onDropdownToggle(event: boolean) {
    if (event) this.getNote().then(); else this.noteChange.emit(this.note);
    this.isEditorOpen.emit({id: this.tender.id, isOpen: event});
    this.isPanelOpen = event;
  }

  actionClick(event: MouseEvent) {
    if (this.mode === 'edition') this.close();
    else {
      event.stopPropagation();
      this.narrowNoteTextArea?.nativeElement.focus()
    }
  }
}

export interface NoteService<T> {
  getNote(id: string): Promise<T>;
  createNote(id: string, note: string): Promise<T>;
  updateNote(id: number, note: string): Promise<T>;
  deleteNote?(id: string): Promise<void>;
}
